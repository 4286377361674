import request from '@/utils/request'

import { format, parseISO } from 'date-fns'

const state = {
    cajaDia: {
        totalprivado: 0,
        totalmixtos: 0,
        totalmass: 0,
        totalproductos: 0,
        totalefectivo: 0,
        totaltarjeta: 0,
        cajaTotal: 0
    },
    opencaja: false,
    cajaInfo: [],
    cajaUser: {},
    cajaCierreDia: {},
    comprasFacturacion: {},
    cajaCierreColaboradorDia: {},
    closeCaja: false
}

const mutations = {
    UPDATE_CAJA_DIA(state, payload) {
        state.cajaDia = payload
    },
    UPDATE_CERRAR_CAJA(state, payload) {
        state.closeCaja = payload
    },
    UPDATE_CAJA_INFO(state, payload) {
        state.cajaInfo = payload
    },
    UPDATE_OPEN_CAJA(state, payload) {
        state.opencaja = payload
    },
    UPDATE_CIERRE_CAJA_DIA(state, payload) {
        state.cajaCierreDia = payload
    },
    UPDATE_COMPRAS_FACTURACION(state, payload) {
        state.comprasFacturacion = payload
    },
    UPDATE_CIERRE_COLABORADOR_CAJA_DIA(state, payload) {
        state.cajaCierreColaboradorDia = payload
    },
    UPDATE_CAJA_DIA_USER(state, payload) {
        state.cajaUser = payload
    }
}
const actions = {
    async getCajaDia({ commit, rootState }) {
        const idColaborador = rootState.users.user.id
        const { idSede } = rootState.users.user
        const today = format(new Date(), 'yyyy-MM-dd')

        const response = await request({
            url: `/api-loopback/tb_reservas/caja/${idSede}/${idColaborador}`,

            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        // //console.log("caja");
        // //console.log(response.data);
        commit('UPDATE_CAJA_DIA', response.data)
    },
    async cierreCaja({ commit, rootState }) {
        const data = {
            sede: rootState.users.user.idSede,
            idColaborador: rootState.users.user.id,
            usuario: rootState.users.user.nombres
        }
        // //console.log(data);
        const response = await request({
            url: '/api-loopback/tb_reservas/cierredecaja',

            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data
        })
        // //console.log("Cierre caja");
        // //console.log(response.data);
        commit('UPDATE_CAJA_DIA', response.data)
        /* commit("UPDATE_OPEN_CAJA", false); */
    },
    async getCajaTotalDia({ commit, rootState, dispatch }, payload) {
        console.log('payload', payload)
        const sede = rootState.users.user.idSede
        console.log('rootState.users.user', rootState.users.user)
        const estado = 1 // 1:procesados 0: no procesados
        const _today = payload.fecha ? format(parseISO(payload.fecha), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd')
        console.log('_today', _today)
        const resultado = await Promise.all([
            request({
                url: `/api-loopback/tb_cierres/consultarCajaDay/v2/${sede}/${estado}/${_today}/0`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            }),
            request({
                url: `/api-loopback/tb_cierres/consultarCajaDay/v2/${sede}/${estado}/${_today}/${rootState.users.user.id}`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            }),
            axios({
                url: `/ms/migraciones/v2.0/comprasGastos`,
                // url: `http://localhost:3000/ms/migraciones/v2.0/comprasGastos`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                },
                params: {
                    typeDate: payload.typeDate,
                    idSede: rootState.users.user.idSede,
                    fechaFiltro: [_today]
                }
            })
        ])
        const dataCaja = { ...resultado[0].data, ...resultado[2].data }
        const pagos = { Deposito: 0, Efectivo: 0, Tarjeta: 0, Transferencias: 0 }

        console.log('dataCaja', dataCaja)
        dataCaja.gastos.map((x) => {
            console.log('x', x)
            switch (x.idTipoPago) {
                case 2:
                    pagos.Tarjeta += Number(x.importeAbonado)
                    break
                case 4:
                    pagos.Deposito += Number(x.importeAbonado)
                    break
                case 3:
                    pagos.Transferencias += Number(x.importeAbonado)
                    break

                default:
                    pagos.Efectivo += Number(x.importeAbonado)
                    break
            }
        })
        console.log('dataCaja', dataCaja)
        console.log('pagos', pagos)
        const payments = {
            totaltarjeta: Number(dataCaja.totaltarjeta).toFixed(2),
            totaltransferencia: Number(dataCaja.totaltransferencia).toFixed(2),
            totaldeposito: Number(dataCaja.totaldeposito).toFixed(2),
            totalefectivo: (Number(dataCaja.totalefectivo) ).toFixed(2),
            totalPagoLinkSoles: (Number(dataCaja.totalPagoLinkSoles) ).toFixed(2),
            totalIzySoles: (Number(dataCaja.totalIzySoles) ).toFixed(2),
            resumen: (Number(dataCaja.totalefectivo) - pagos.Efectivo).toFixed(2)
        }
        console.log('payments', payments)
        commit('UPDATE_CIERRE_CAJA_DIA', { ...dataCaja, ...payments })
        commit('UPDATE_CIERRE_COLABORADOR_CAJA_DIA', resultado[1].data)
    },
    async getCajaTotalUser({ commit, rootState }, payload) {
        const idColaborador = rootState.users.user.id
        const sede = rootState.users.user.idSede
        const estado = 1 // 1:procesados 0: no procesados
        const today = payload.fecha ? format(payload.fecha, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd')
        /*    const today = "2019-05-23"; */

        const response = await request({
            url: `/api-loopback/tb_reservas/consultarReservas/${sede}/${idColaborador}/${estado}/${today}`,

            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        // //console.log("caja-info_USER");
        const resultado = response.data

        // //console.log(resultado);
        commit('UPDATE_CAJA_DIA_USER', resultado)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
