import { format, parseISO } from 'date-fns'
import request from '@/utils/request'
import { changeSoles } from '@/utils/genericUtils'

const state = {
    listaCotizaciones: []
}
const getters = {
    listaCotizaciones: (state) => {
        return state.listaCotizaciones
    }
}

const mutations = {
    UPDATE_COTIZACIONES(state, payload) {
        state.listaCotizaciones = payload
    }
}
const actions = {
    async getLista({ commit, state, rootState }, payload) {
        //console.log('GET_COTIZACIONES', payload)
        const { idSede, idBussines } = rootState.users.user
        const fromDate = payload.fechaFiltro ? format(parseISO(payload.fechaFiltro), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd')
        const numeroDocumento = payload.numeroDocumento
        const timestamp = Date.now()
        //console.log('idBussines ', idBussines)
        if (state.listaCotizaciones.length < 1 || payload.state) {
            //console.log('cotizaciones_from_server')
            const { data } = await request({
                url: `/ms/migraciones/v1.0/cotizacion`,
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                data: {
                    idSede: String(idSede),
                    fromDate: String(fromDate),
                    numeroDocumento,
                    timestamp: String(timestamp)
                }
            })
            console.log("response_cotizacion", data.data);
            commit('UPDATE_COTIZACIONES', data.data)
            return data.data
        } else {
            //console.log('cotizaciones_from_cache')
        }
    },
    async postCotizaciones({ rootState, dispatch }, data) {
        const { idSede } = rootState.users.user
        console.log('payload : ', data)
        const response = await request({
            url: '/api-loopback/tb_cotizaciones',
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data
        })
        //console.log('postCotizaciones')
        //console.log(response.data)
        if (data.sendMail) {
            await dispatch(
                'reporte/sendEmailCotizacion',
                {
                    ...data,
                    ...response.data,
                    _id: String('000000' + response.data.id).slice(-6)
                },
                {
                    root: true
                }
            )
        }
    },
    async changeDollars({ rootState, dispatch }, data) {
        console.log('data', data)
        const newItemsProduct = changeSoles(JSON.parse(data.itemsProductos), ['price', 'subTotal', 'descuentos', 'comision'], data.tc)
        console.log('newItemsProduct', newItemsProduct)
        const list = {
            ...data,
            itemsProductos: newItemsProduct,
            montoSoles: String((Number(data.montoSoles) + Number(data.montoDolares) * data.tc).toFixed(2)),
            montoDolares: '0',
            idTipoMoneda: 1
        }
        console.log('list', list)
        await request({
            url: `/api-loopback/tb_cotizaciones/${data.id}`,
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            data: list
        })
    },
    async updateCotizaciones({ rootState, dispatch }, data) {
        console.log('data ', data)
        const response = await request({
            url: `/api-loopback/tb_cotizaciones/${data.id}`,
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            data
        })
        console.log('UP_COTI', response.data)
        if (data.sendMail) {
            await dispatch(
                'reporte/sendEmailCotizacion',
                { ...data, ...response.data, _id: String('000000' + response.data.id).slice(-6) },
                {
                    root: true
                }
            )
        }
    },
    async filterCotizacionesMounth({ rootState, dispatch }, data) {
        console.log("data", data)
    },
    async deleteCotizacion({ rootState }, data) {
        //console.log('deleteCotizacion ', data)
        const response = await request({
            url: `/api-loopback/tb_cotizaciones/${data.id}`,
            method: 'DELETE',
            headers: {
                'content-type': 'application/json'
            }
        })
        return response.data
        // //console.log('UP_COTI', response.data)
    },
    async deleteClientes({ }) {
        // //console.log("GET_CLIENTE");
    },
    async getCotizacionesByMonth({ commit, rootState }, payload) {
        const { date, idColaborador } = payload
        let fechaInicial, fechaFinal;
        if (date.length === 7) { // Si la variable es un mes
            fechaInicial = `${date}-01T00:00:00.000`;
            fechaFinal = `${date}-31T23:59:59.999`;
        } else { // Si la variable es una fecha completa
            fechaInicial = `${date}T00:00:00.000`;
            fechaFinal = `${date}T23:59:59.999`;
        }
        const filter = {
            where: {
                and: [
                    {
                        created_at: { between: [fechaInicial, fechaFinal] }
                    },
                    {
                        idColaborador: idColaborador
                    },

                ]
            }
        }
        const { data } = await request({
            url: `/api-loopback/tb_cotizaciones?filter=${JSON.stringify(filter)}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        return data
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
