import { format } from 'date-fns'
import request from '@/utils/request'
import { downloadFile } from '@/utils/genericUtils'

const state = {
    listaAsistencia: [],
    listaColaborador: [],
    listaReporte: []
}

const getters = {
    listaIngreso(state) {
        return state.listaAsistencia.filter((x) => x.idTipoRegistro === 1)
    },
    listaEgreso(state) {
        return state.listaAsistencia.filter((x) => x.idTipoRegistro === 2)
    },
    listaInicioRefrigerio(state) {
        return state.listaAsistencia.filter((x) => x.idTipoRegistro === 3)
    },
    listaFinRefrigerio(state) {
        return state.listaAsistencia.filter((x) => x.idTipoRegistro === 4)
    },
    listaFiltradaCol(state) {
        return [...new Set(state.listaAsistencia.map((data) => data.idColaborador))]
    }
}
const mutations = {
    UPDATE_LISTA(state, payload) {
        state.listaAsistencia = payload
    },
    UPDATE_LISTA_REPORTE(state, payload) {
        state.listaReporte = payload
    },
    UPDATE_LISTA_COLABORADOR(state, payload) {
        state.listaColaborador = payload
    }
}
const actions = {
    async getListaColaborador({ commit, rootState }) {
        const { idSede } = rootState.users.user
        const today = format(new Date(), 'yyyy-MM-dd')
        //console.log('idSede ', idSede)

        const response = await request({
            url: `/api-loopback/tb_colaboradors?filter={"where":{"and":[{"idSede": ${idSede}}, {"status": 1}]}}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
                //
            }
        })

        //console.log('GET_LISTA_COLABORADOR')
        commit('UPDATE_LISTA_COLABORADOR', response.data)
    },
    async downloadReporte({ commit, rootState }, payload) {
        console.log('payload', payload)
        const response = await request({
            url: `/ms/carbone/v1.0/report/17`,
            method: 'POST',
            responseType: 'blob',
            headers: {
                //
            },
            data: payload
        })
        const name = `REPORTE_ACTIVIDAD`
        await downloadFile(response.data, name, 'xlsx')
    },
    async getListaReporte({ commit, rootState }, payload) {
        const { firstDay, nextDay, id_role, colab, idColaborador } = payload
        let url
        if (idColaborador) {
            url = `/api-loopback/tb_reporte_asistencia?filter={ "where": { "and": [{ "dia": { "between": ["${firstDay}", "${nextDay}"] }},{"idColaborador":${idColaborador}}] } ,  "order": "id DESC"
        }`
        } else {
            url = `/api-loopback/tb_reporte_asistencia?filter={ "where": { "dia": { "between": ["${firstDay}", "${nextDay}"] } },  "order": "id DESC"
        }`
        }
        const { data } = await request({
            url,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        const filter = []
        data.filter((x) => {
            colab.filter((y) => {
                y.id == x.idColaborador && filter.push(x)
            })
        })
        const registro = id_role != 3 ? filter : data
        //console.log('GET_LISTA_COLABORADOR')
        commit('UPDATE_LISTA_REPORTE', registro)
    },
    async getLista({ commit, rootState }, payload) {
        const { idSede, tipo, fecha } = payload
        const response = await request({
            url: `/api-loopback/lb_asistencia/getAsistencia/${idSede}/${tipo}/${fecha}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
                //
            }
        })
        console.log('GET_LISTA_ASISTENCIA_FROM_SERVER', response.data)
        commit('UPDATE_LISTA', response.data)
    },
    async findAsistencia({ rootState }, payload) {
        const { fecha, idColaborador } = payload
        console.log('fecha', fecha)
        const { data } = await request({
            url: `/api-loopback/lb_asistencia?filter={"order":["created_at DESC"],"limit":10,"where":{"and":[{"idColaborador":${idColaborador}},{"idTipoRegistro":1}]}}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
                //
            }
        })
        const BuscarAsistencia = data.find((x) => x.created_at.split('T')[0] == fecha)
        return BuscarAsistencia ? false : true
    },
    async postReporte({ rootState }, payload) {
        console.log('payload', payload)
        const { date, hourEntry, hourOutput, idColaborador, text, numTicket, numOt, jsActividades } = payload
        await request({
            url: '/api-loopback/tb_reporte_asistencia',
            method: 'POST',
            headers: {
                'content-type': 'application/json'
                //
            },
            data: {
                idColaborador,
                dia: date,
                numOt,
                numTicket,
                horarioIngreso: hourEntry,
                horaSalida: hourOutput,
                actividades: text,
                jsActividades: jsActividades
            }
        })
    },
    async updateReporte({ rootState }, payload) {
        console.log('payload', payload)
        const { date, hourEntry, hourOutput, idColaborador, text, id } = payload
        await request({
            url: `/api-loopback/tb_reporte_asistencia/${id}`,
            method: 'PATCH',
            headers: {
                'content-type': 'application/json'
                //
            },
            data: {
                idColaborador,
                dia: date,
                horarioIngreso: hourEntry,
                horaSalida: hourOutput,
                actividades: text
            }
        })
    },
    async deleteReporte({ rootState }, payload) {
        console.log('payload', payload)
        const { id } = payload
        await request({
            url: `/api-loopback/tb_reporte_asistencia/${id}`,
            method: 'DELETE',
            headers: {
                'content-type': 'application/json'
                //
            }
        })
    },
    async postLista({ rootState }, payload) {
        const data = {
            ...payload,
            idSede: rootState.users.user.idSede,
            idColaborador: rootState.users.user.id
        }
        console.log('postLista', data)
        const response = await request({
            url: '/api-loopback/lb_asistencia/postAsistenciaVal',
            method: 'POST',
            headers: {
                'content-type': 'application/json'
                //
            },
            data
        })
        //console.log(response.data)
        return response.data
    },
    async borrarRegistro({}, payload) {
        const { iddata } = payload
        //console.log(payload)
        const response = await request({
            url: `/api-loopback/lb_asistencia/${iddata}`,
            method: 'DELETE',
            headers: {
                'content-type': 'application/json'
                //
            }
        })
        //console.log(response.data)
        return response.data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
