import { format, subDays, subMonths } from 'date-fns'
import DefaultForm from '@/utils/defaultForm'
import { sortItems } from '@/utils/genericUtils'
import request from '@/utils/request'

/* ## callback venta ## */
const state = {
    listaPagos: [],
    datos: DefaultForm.formCompras()
}

const getters = {}

const mutations = {
    UPDATE_LISTA_PAGOS(state, payload) {
        state.listaPagos = payload
    },
    UPDATE_COMPRA(state, payload) {
        state.datos = payload
    },
    RESET_COMPRA(state, payload) {
        state.datos = DefaultForm.formCompras()
    }
}

const actions = {
    async postInitCompras({ state, commit, rootState }, payload) {
        const { idSede, idBussines } = rootState.users.user

        //console.log('REQ', payload)
        delete state.datos.id
        const response = await request({
            url: `/api-loopback/tm_compras`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                ...state.datos,
                idSede,
                idBussines
            }
        })
        //console.log('creating_basic_compra', response.data)
        commit('UPDATE_COMPRA', response.data)
    },
    async listaPagosById({ commit, state }, payload) {
        const idCompra = state.datos.id
        let data = []
        //console.log('idCompra ', idCompra)

        if (idCompra) {
            const response = await request({
                url: `/api-loopback/tm_pagos_compras?filter={"where":{"and":[{"idCompra": ${idCompra}},{"status": 1}]}}`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })
            data = response.data
            //console.log('get_Lista_pagos_from_server', data)
        } else {
            //console.log('not_id_found', data)
        }
        //console.log('error,log')
        //console.log('data ', data)
        commit('UPDATE_LISTA_PAGOS', data)
    },
    async postPagos({ commit, state, dispatch }, payload) {
        //console.log('state', state)
        const idCompra = state.datos.id
        //console.log('idCompra ', idCompra)
        const idColaborador = state.datos.idColaborador
        if (idCompra) {
            const { data } = await request({
                url: `/api-loopback/tm_pagos_compras`,
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                data: {
                    ...payload,
                    status: 1,
                    idCompra,
                    idColaborador
                }
            })

            //console.log('post_pagos_from_server', data)
            await dispatch('listaPagosById', { id: payload.idCompra })
        } else {
            //console.log('not_id_found', data)
        }
        // //console.log(response.data);
    },
    async deletePagos({ commit, rootState, dispatch }, payload) {
        let data = []
        if (payload.id) {
            const response = await request({
                url: `/api-loopback/tm_pagos_compras/${payload.id}`,
                method: 'PUT',
                headers: {
                    'content-type': 'application/json'
                },
                data: {
                    ...payload,
                    status: 0
                }
            })
            await dispatch('listaPagosById', { id: payload.idCompra })
        } else {
            //console.log('not_id_found', data)
        }

        // //console.log(response.data);
    },
    async sendPurchase({ commit, rootState, dispatch }, payload) {
        //console.log('hola')
        let productXML = []
        const { user } = payload
        const formData = new FormData()
        //console.log('payload', payload)
        for (let i = 0; i < payload.file.length; i++) {
            productXML.push(payload.file[i])
            formData.append(`uploadedImages`, payload.file[i])
        }
        console.log('productXML', productXML)

        // console.log('formData', formData)
        const { data } = await axios({
            url: `/ms/servicios/v1/file/purchase?idSede=${user.idSede}&idBussines=${user.idBussines}&idColaborador=${user.id}`,
            method: 'POST',
            headers: { 'content-type': 'multipart/form-data' },
            data: formData
        })
        console.log('data', data)
        return data
    },
    async getFromcompras({ commit, rootState, dispatch }, payload) {
        console.log('payload', payload)
        console.log('rootState.users.user', rootState.users.user)
        const _today = payload.fecha ? format(parseISO(payload.fecha), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd')
        console.log('gastos_getFromcompras', payload.gastos)
        const _gastos = []
        payload.gastos.map((x) => {
            console.log('fechaEmision',x.fechaEmision)
            _gastos.push({
                ...x,
                idTipoPago: x.tipoPago,
                efectivoSoles: x.importeAbonadoSoles,
                tarjetaSoles: x.importeAbonadoDolares,
                // transferenciaSoles: 0,
                // depositoSoles: 0,
                tipoComprobante: x.idTipoComprobante==1?'FACTURA':'BOLETA',
            })
        })
        console.log('_gastos',_gastos)
        const { data } = await request({
            url: `/ms/migraciones/v2.0/comprasGastos`,
            // url: `http://localhost:3000/ms/migraciones/v2.0/comprasGastos`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            },
            params: {
                typeDate: payload.typeDate,
                idSede: rootState.users.user.idSede,
                fechaFiltro: payload.fechaFiltro
            }
        })

        console.log('result', data)
        return data.gastos.concat(_gastos)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
