<template>
    <v-app>
        <router-view />
        <v-snackbar v-model="showSnackBar.state" center :timeout="showSnackBar.timeout || 3000" top right :color="showSnackBar.color">{{
            showSnackBar.text
        }}</v-snackbar>

        <v-dialog v-model="openModalDefault.state" hide-overlay persistent width="200">
            <v-card color="green" dark>
                <v-card-text>
                    {{ openModalDefault.text }}
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
                <v-card-actions v-if="openModalDefault.close">
                    <v-spacer></v-spacer>
                    <v-btn dark text @click="closeModal"> CERRAR </v-btn> <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import { mapState } from 'vuex'

import { sleep } from '@/utils/genericUtils'

export default {
    name: 'App',
    computed: {
        ...mapState('mainUI', ['showSnackBar', 'openModalDefault']),
        ...mapState('users', ['user'])
    },
    methods: {
        closeModal() {
            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
        }
    },
    watch: {
        async openModalDefault(v) {
            console.log('vvvv ', v)
            if (v['isTimeout']) {
                await sleep(2500)
                this.$store.commit('mainUI/OPEN_MODAL', { state: false })
            }
        }
    },
    async created() {
        //console.log('user ', this.user)
        this.$store.commit('mainUI/OPEN_BAR', { state: false })
        this.$store.commit('mainUI/OPEN_MODAL', { state: false })
        //console.log(' location.hostname', location.hostname)
        const cubaspa = 'intranet.cubaspa.com.pe'
        const kuntespa = 'intranet.kuntespa.com'
        const kuntespasolu = 'intranet.kuntespasoluciones.innout.pe'
        //const hostname = location.hostname == kuntespa || location.hostname == cubaspa ? location.hostname : cubaspa
        const hostname = location.hostname == 'localhost' ? kuntespa : location.hostname
        const _hostname = hostname.slice(9, 100)
        //console.log('hostname ', hostname, 'domain ', _hostname)
        await this.$store.dispatch('bussines/getBussines', JSON.stringify(_hostname))
    }
}
</script>
<style lang="css">
.required label::after {
    content: '*';
}
</style>
