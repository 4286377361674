import { format, addDays, parseISO } from 'date-fns'
import { Gastos, Ventas, Orden } from '../../models/reportes'
import { sortItems, setParseJson, formatNumber, downloadFile, getValue, financial, callAsync, exportXLSX } from '@/utils/genericUtils'
import request from '@/utils/request'
import { es } from 'date-fns/locale'
const { formatInTimeZone } = require('date-fns-tz')
import { eoLocale } from 'date-fns/locale/eo'

const state = {
    reporteMesSede: {},
    reporteVentas: [],
    wachtReport: 5
}

const getters = {}

const mutations = {
    UPDATE_REPORTE_MES(state, payload) {
        state.reporteMesSede = payload
    },
    UPDATE_REPORTE_VENTAS_MES(state, payload) {
        state.reporteVentas = payload
    },
    UPDATE_WHACHTREPORT(state, payload) {
        state.wachtReport = payload
    }
}

const actions = {
    async reporteMesSede({ commit, rootState, dispatch }, payload) {
        console.log('PAYLOAD: ', payload)

        const idSede = payload.id
        const between = JSON.stringify([payload.fecha + '-00', payload.fecha + '-31'])
        const sede = rootState.users.user.idSede
        const estado = 1 // 1:procesados 0: no procesados
        const colaborador = payload.colaborador === '00' ? rootState.users.user.id : 0

        const _today = payload.fecha ? format(parseISO(payload.fecha), 'yyyy-MM') : format(new Date(), 'yyyy-MM')
        //const fromDate =

        //const between = JSON.stringify(format(new Date(), "yyyy-MM").concat("-00"));

        const reserva = async (name) => {
            ////console.log('waiting..', name)
            const response = await request({
                url: `/api-loopback/tb_cierres/consultarCajaDay/v2/${sede}/${estado}/${_today}/${colaborador}`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                    ////
                }
            })
            return response.data
        }

        const resultado = await Promise.all([reserva('tb_pre_reservas'), reserva('tb_reservas')])
        const gastosTotal = await gastos()
        ////console.log('GASTOS:', gastosTotal)
        const listaCategoria = rootState.utilities.listaCategoria
        let listaItemCategoria = []
        listaCategoria.map((v) => {
            let suma = 0
            const listaFiltrada = gastosTotal.filter((a) => a.id_tipo_gasto == v.id_tipo_gasto)
            listaFiltrada.map((x) => (suma = suma + parseFloat(x.importe)))
            listaItemCategoria.push({ id: v.id_tipo_gasto, suma })
        })

        resultado.map((items) => {
            items.map((v) => {
                const adelanto = v.fecha_reservada ? parseFloat(v.adelanto) : 0
                //privadoTotal.push()
                montoTotal = parseFloat(v.importeTotal) + montoTotal - adelanto
                montoTarjeta = montoTarjeta + parseFloat(v.tarjeta)
                montoTransferencia = montoTransferencia + parseFloat(v.transferencia)
                montoEfectivo = montoEfectivo + parseFloat(v.efectivo)
                montoAdelanto = montoAdelanto + adelanto
                montoPrivado = montoPrivado + getSuma(v.privados, 'subTotal')
                numeroPrivado = numeroPrivado + v.privados.length
                privadoTotal = privadoTotal.concat(v.privados)
                montoMasajes = montoMasajes + getSuma(v.masajes, 'subTotal')
                masajesTotal = masajesTotal.concat(v.masajes)
                numeroMasajes = numeroMasajes + v.masajes.length
                montoMixtos = montoMixtos + getSuma(v.mixtos, 'subTotal')
                mixtosTotal = mixtosTotal.concat(v.mixtos)
                numeroMixtos = numeroMixtos + v.mixtos.length
                montoProductos = montoProductos + getSuma(v.productos, 'subTotal')
            })
            /* PRE-RESERVAS */
        })

        // //console.log('PRE-RESERVAS', resultado)
        // //console.log('PRIVADOS', privadoTotal.length)

        const getArrayItem = (items, name, title, importe) => {
            let res = []
            let newItem = []
            items.map((valor) => {
                if (res.filter((v) => v.id == valor[name]).length < 1) {
                    if (valor[name]) {
                        res.push({ id: valor[name], title: valor[title] })
                    }
                }
            })
            // //console.log('ITEMS: ', items)
            res.map((v) => {
                let suma = 0
                const data = items.filter((val) => val[name] == v.id)
                data.map((b) => {
                    const q = b['quantity']
                    suma = suma + parseFloat(b[importe] * (q ? q : 1))
                })
                newItem.push({ id: v.id, title: v.title, suma, data })
            })
            return newItem
        }
        const privadoItemTotal = getArrayItem(privadoTotal, 'id_hab_sede', 'room', 'subTotal')
        const masajesItemTotal = getArrayItem(masajesTotal, 'massage', 'massage', 'subTotal')
        const mixtosItemTotal = getArrayItem(mixtosTotal, 'id_mixto', 'mixto', 'subTotal')
        const gastosItemTotal = getArrayItem(gastosTotal, 'id_tipo_gasto', 'tipo_gasto', 'importe')
        const montoTotalGastos = getSuma(gastosItemTotal, 'suma')
        const montoTotalPrivados = getSuma(privadoItemTotal, 'suma')
        const montoTotalMasajes = getSuma(masajesItemTotal, 'suma')
        const montoTotalMixtos = getSuma(mixtosItemTotal, 'suma')
        /*  //console.log('PRIVADOS_ALL', privadoItemTotal)
        //console.log('MIXTOS_ALL', mixtosItemTotal)
        //console.log('MASAJES_ALL', masajesItemTotal)
        //console.log('GASTOS_ALL', gastosItemTotal)
        //console.log('MONTO_TOTAL_MES', montoTotal) */
        const reporteExcel = async (valor) => {
            const response = await request({
                url: `/api-orquestador/users/v2.0/reporte/venta`,
                method: 'POST',
                responseType: 'blob', // important
                headers: {
                    //
                    /* "content-type":"application/octet-stream" */
                },
                data: {
                    ...valor
                }
            })
            //console.log('OUTPUT : ', response.data)
            const name = `REPORTE_${payload.province}_${payload.id}_${payload.fecha}}`
            await downloadFile(response.data, name, 'xlsx')
        }
        const _montoTotal = montoEfectivo + montoTarjeta + montoTransferencia - montoAdelanto
        const __montoTotal = montoPrivado + montoMixtos + montoMasajes + montoProductos - montoAdelanto
        utilidadBruta = montoTotal - listaItemCategoria.find((x) => x.id === 1).suma
        utilidadNeta = montoTotal - montoTotalGastos

        const output = {
            ...payload,
            montoTotal,
            montoTarjeta,
            montoEfectivo,
            montoTransferencia,
            montoAdelanto,
            montoPrivado,
            privadoItemTotal,
            numeroPrivado,
            montoMixtos,
            numeroMixtos,
            montoMasajes,
            numeroMasajes,
            montoProductos,
            montoTotalPrivados,
            montoTotalMasajes,
            montoTotalMixtos,
            montoTotalGastos,
            listaItemCategoria,
            utilidadBruta,
            utilidadNeta,
            __montoTotal,
            _montoTotal
        }
        await reporteExcel(output)
        commit('UPDATE_REPORTE_MES', output)
    },
    async ticketSodimac({ rootState, rootGetters, dispatch }, payload) {
        //console.log('all body ', data)
        //console.log('valorSecuencial', valorSecuencial)
        const { item, idVenta, CodeBar, sku } = payload
        const response = await request({
            url: `/api-orquestador/bar/v2.0/process/code`,
            method: 'POST',
            responseType: 'blob',
            headers: {},
            data: {
                items: item,
                idVenta: idVenta,
                codigoBarra: CodeBar,
                sku: sku
            }
        })

        console.log('OUTPUT : ', response.data)
        const timestamp = Date.now()
        const name = `ReporteSodimac_${timestamp}`
        return await downloadFile(response.data, name, 'pdf', true)
    },

    async generatedCodeBar({ rootState, rootGetters, dispatch }, payload) {
        const { upcSodimac, descSodimac, skuSodimac } = payload
        const response = await request({
            url: `/api-orquestador/etiqueta/v1.0/generar`,
            method: 'POST',
            responseType: 'blob',
            headers: {},
            data: {
                descSodimac: descSodimac,
                upcSodimac: upcSodimac,
                skuSodimac: skuSodimac
            }
        })
        console.log('OUTPUT : ', response.data)
        const timestamp = Date.now()
        const name = `EtiquetaCodeBar${timestamp}`
        return await downloadFile(response.data, name, 'pdf', true)
    },

    async consultStatus({ rootState, rootGetters, dispatch }, payload) {
        await request({
            url: `/api-orquestador/v2.0/consultar/Estado`,
            method: 'POST',
            responseType: 'blob',
            headers: {},
            data: { idVenta: payload }
        })
        return
    },

    async generarContrato({ rootState }, data) {
        const itemsProductos = setParseJson(data.productos)
        let _itemsProductos = []
        console.log('rootState ', rootState)
        console.log('rootState.clientes ', rootState.clientes)
        console.log('rootState.clientes.listaClientes ', rootState.clientes.listaClientes)
        const clientes = rootState.clientes.listaClientes

        itemsProductos.map((y) =>
            _itemsProductos.push({
                dC: y.description,
                iC: y.internal_id ? y.internal_id : y.idCodigo,
                mC: y.marca,
                uN: y.unidades,
                pR: y.price,
                sT: y.subTotal
            })
        )
        const { user } = rootState.users
        const diaSemanal = formatInTimeZone(new Date(), 'America/Lima', 'EEEE', { locale: es })
        const myTimezone = formatInTimeZone(new Date(), 'America/Lima', 'yyyy-MM-dd').split('-', 3)
        const annio = myTimezone[0]
        const mes = myTimezone[1]
        const dia = myTimezone[2]
        const paisCurrency = data.idTipoMoneda === 1 ? 'es-PE' : 'en-US'
        const moneda = data.idTipoMoneda === 1 ? 'PEN' : 'USD'
        const currency = function (number) {
            return new Intl.NumberFormat(paisCurrency, { style: 'currency', currency: moneda, minimumFractionDigits: 2 }).format(number)
        }
        const cliente = clientes.find((x) => x.id == data.idCliente)
        console.log('cliente ', cliente)

        const response = await request({
            url: `/ms/carbone/v1.0/report/13`,
            method: 'POST',
            responseType: 'blob',
            headers: {
                //
            },
            data: {
                id: data.id,
                diaSemanal,
                dia,
                mes,
                annio,
                vendedora: `${user.nombres} ${user.apellidos}`,
                dniVendedora: user.documento,
                nombreCliente: cliente.name,
                clienteDocumento: cliente.number,
                direccion: data.direccion,
                precioTotal: currency(data.importeTotal),
                precioPorct: currency(data.importeTotal / 2),
                diasHabiles: '5',
                itemsProductos: _itemsProductos
            }
        })
        const name = `CONTRATO_${data.id}_${cliente.name}`
        //console.log('OUTPUT : ', response.data)
        return await downloadFile(response.data, name, 'docx')
    },

    async reporteCotizacion({ rootState }, data) {
        console.log('reporteCotizacion', data)

        const dataProyect = typeof data.datosProyecto == 'object' ? data.datosProyecto : JSON.parse(data.datosProyecto)
        //console.log('dataProyect', dataProyect)
        const bussines = rootState.bussines.bussines
        const user = rootState.users.user
        const userTelefono = rootState.users.user.documento == '76342435' ? '998119929' : user.telefono
        const asesorComercial = `${user.nombres} ${user.apellidos} - ${userTelefono} - ${user.correo}`

        const tipoMonedaOrigen = rootState.utilities.tipoMoneda.find((x) => x.id === data.idTipoMoneda)
        const tipoPagoOrigen = rootState.utilities.tipoPago.find((x) => x.id == data.idTipoPago)
        const _bussines = {
            ruc: bussines.ruc,
            direccion: bussines.direccion,
            correo: bussines.correo,
            telefono: bussines.telefono
        }
        const itemsProductos = setParseJson(data.itemsProductos)
        //console.log('itemsProductos', itemsProductos)
        const cliente = {
            fullName: data.full_name,
            numeroDocumento: data.numeroDocumento,
            direccion: data.direccion,
            distrito: data.distrito,
            tipoMoneda: tipoMonedaOrigen.value,
            montoTipoCambio: data.tipoCambio,
            tipoPago: tipoPagoOrigen.descripcion,
            telefono: data.telefono,
            correo: data.correo,
            diasEntrega: data.diasEntrega
        }
        let subTotal = 0
        let descTotal = 0
        let igvTotal = 0
        let total = 0
        itemsProductos.map((x) => {
            total += Number(x.subTotal)
            descTotal += Number(x.descuentos)
        })
        const isReciboIngreso = data.idTipoComprobante == 5
        subTotal = isReciboIngreso ? total : total / 1.18
        igvTotal = isReciboIngreso ? 0 : total - subTotal

        const importes = {
            sT: formatNumber(subTotal, data.idTipoMoneda),
            dT: formatNumber(descTotal, data.idTipoMoneda),
            iT: formatNumber(igvTotal, data.idTipoMoneda),
            tL: formatNumber(total, data.idTipoMoneda)
        }

        let myImporte
        console.log('data.tipoCambio', data.tipoCambio)
        if (data.idTipoComprobante == 3) {
            myImporte = {
                'Sub Total': formatNumber(subTotal, data.idTipoMoneda),
                IGV: formatNumber(0, data.idTipoMoneda),
                TOTAL: formatNumber(subTotal, data.idTipoMoneda)
            }
        } else if (data.idTipoMoneda == 1) {
            myImporte = {
                'Sub Total': formatNumber(subTotal, data.idTipoMoneda),
                IGV: formatNumber(igvTotal, data.idTipoMoneda),
                TOTAL: formatNumber(total, data.idTipoMoneda)
            }
        } else {
            if (data.tipoCambio == 0 || !data.tipoCambio) {
                myImporte = {
                    'Sub Total': formatNumber(subTotal, data.idTipoMoneda),
                    Igv: formatNumber(igvTotal, data.idTipoMoneda),
                    'Total Dolares': formatNumber(total, data.idTipoMoneda)
                }
            } else {
                myImporte = {
                    'Sub Total': formatNumber(subTotal, data.idTipoMoneda),
                    Igv: formatNumber(igvTotal, data.idTipoMoneda),
                    'Total Dolares': formatNumber(total, data.idTipoMoneda),
                    'Total Soles': formatNumber(total * data.tipoCambio, 1)
                }
            }
        }
        //console.log('importes ', importes)
        let _itemsProductos = []
        //console.log('itemsProductos', itemsProductos)
        itemsProductos.map((y) => {
            _itemsProductos.push({
                dS: y.descuentos,
                dC: y.description,
                iC: y.internal_id ? y.internal_id : y.idCodigo,
                mC: y.marca,
                uN: y.unidades,
                pR: y.price + Number(y.comision),
                sT: y.subTotal
            })
        })
        console.log('user', user)
        console.log('data', data)
        const response = await request({
            url: `/ms/carbone/v1.0/report/${user.idBussines === 2 ? '2' : '14'}`,
            method: 'POST',
            responseType: 'blob',
            headers: {
                //
            },
            data: {
                _id: data._id,
                fechaVencimiento: format(parseISO(data.fechaVencimiento), 'dd/MM/yyyy'),
                fechaEmision: format(parseISO(data.fechaEmision), 'dd/MM/yyyy'),
                bussines: _bussines,
                asesorComercial,
                cliente,
                largo: dataProyect ? dataProyect.largo : '-',
                altura: dataProyect ? dataProyect.alto : '-',
                ancho: dataProyect ? dataProyect.ancho : '-',
                importes,
                myImporte,
                observaciones: data.observaciones,
                formaPago: data.formaPago,
                itemsProductos: _itemsProductos
            }
        })
        const name = `COTIZACION_${data._id}_${cliente.fullName}`

        //console.log('OUTPUT : ', response.data)

        return await downloadFile(response.data, name, 'pdf')
    },

    async printActoEntrega({ rootState }, payload) {
        console.log('payload', payload)
        const { idOrdenCompra, productos, full_name, numeroDocumento, direccion, idCotizacion } = payload
        let producto = ''
        productos.map((x) => {
            producto = producto.concat(' ', x.description)
        })
        const fecha = formatInTimeZone(new Date(), 'America/Lima', "dd 'de' MMMM 'de' yyyy", { locale: es })
        const response = await request({
            url: `/ms/carbone/v1.0/report/15`,
            method: 'POST',
            responseType: 'blob', // important
            headers: {
                /* "content-type":"application/octet-stream" */
            },
            data: {
                nrOrdenServicio: idOrdenCompra,
                cliente: full_name,
                numeroDocumento: numeroDocumento,
                direccion,
                producto,
                numeroCotizacion: idCotizacion,
                fecha,
                nombreCompletoCliente: full_name
            }
        })
        const name = `ACTA_DE_ENTREGA_DE_SERVICIO`
        await downloadFile(response.data, name, 'docx')
        console.log('response', response)
    },

    async reporteVentaMesSedeNew({ rootState, rootGetters, dispatch }, payload) {
        try {
            //console.log('payload ', payload)
            const sede = getValue(payload.idSede, rootGetters['sedes/_sedes'], 'id', 'detail')
            const pre_reservas = await callAsync(`/api-loopback/tm_ventas/getVentasByDate/${payload.idSede}/${payload.fecha}/3/1`, 'GET')

            let items = []
            pre_reservas.map((y) => {
                ////console.log('y___', y.id)
                const productos = JSON.parse(y.productos)

                const detalle = productos ? (productos.length > 0 ? productos[0].description : '') : ''

                const body = new Ventas({
                    ...y,
                    estado: getValue(y.finalizado, rootState.utilities.selectEstado, 'id', 'text'),
                    tipoComprobante: getValue(y.idTipoComprobante, rootState.utilities.tipoComprobante, 'id', 'descripcion'),
                    tipoDocumento: getValue(y.idTipoDocumento, rootState.utilities.tipoDocumento, 'id', 'text'),
                    sede: getValue(y.idSede, rootGetters['sedes/_sedes'], 'id', 'detail'),
                    moneda: getValue(y.idTipoMoneda, rootState.utilities.tipoMoneda, 'id', 'text'),
                    bancoDestino: getValue(y.idBancoDestino, rootState.utilities.tipoBancoDestino, 'id', 'text'),
                    tipoPago: getValue(y.tipoPago, rootState.utilities.paymentMethod, 'id', 'text'),
                    importeAbonadoSoles: y.importeAbonadoSoles,
                    importeAbonadoDolares: y.importeAbonadoDolares,
                    detalle,
                    observaciones: y.observation
                })

                items.push(body)
            })
            const output = sortItems(items, 'check_in', -1, 2)
            //console.log('output', output)

            const response = await request({
                url: `/ms/carbone/v1.0/report/3`,
                method: 'POST',
                responseType: 'blob', // important
                headers: {
                    //
                    /* "content-type":"application/octet-stream" */
                },
                data: output
            })
            //console.log('OUTPUT : ', response.data)
            const name = `REPORTE_VENTA_MES_${sede}`
            await downloadFile(response.data, name, 'xlsx')
        } catch (error) {
            //console.log('error ', error)
        }
    },
    async reporteOrdenProduccion({ rootState, rootGetters, dispatch }, payload) {
        //console.log('reporteOrdenProduccion', payload)
        const { id, annio, vendedora, fechaEmision, ordenPedido, producto, color, medida, material, observacion, personalCargo } = payload
        let fechaEntrega = ''
        if (ordenPedido) {
            const { data } = await axios({
                url: `/api-loopback/tm_ventas/${ordenPedido}`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })
            fechaEntrega = data.check_in
        }
        const response = await request({
            url: `/ms/carbone/v1.0/report/11`,
            method: 'POST',
            responseType: 'blob', // important
            headers: {
                //
                /* "content-type":"application/octet-stream" */
            },
            data: {
                fechaEntrega,
                _id: id,
                fullName: vendedora,
                personalCargo,
                fechaEmision,
                ordenPedido,
                producto,
                color: color ? color : 'NO DEFINIDO',
                medida: medida ? medida : 'NO DEFINIDO',
                material,
                observacion
            }
        })
        //console.log('OUTPUT : ', response.data)
        const name = `ORDEN PRODUCCION N ${id}`
        await downloadFile(response.data, name, 'docx')
    },

    async reporteOrdenEquipamiento({ rootState, rootGetters, dispatch }, payload) {
        console.log('reporteOrdenEquipamiento', payload)
        const { orden, vendedor, fecha, ordenpedido, personacargo, producto, model, medida, color, posicion, observacion } = payload
        //console.log('payload.items', payload.items)
        let fechaEntrega = 'NO DEFINIDO'
        if (ordenpedido) {
            const { data } = await axios({
                url: `/api-loopback/tm_ventas/${ordenpedido}`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })
            fechaEntrega = data.check_in
        }

        const response = await request({
            url: `/ms/carbone/v1.0/report/12`,
            method: 'POST',
            responseType: 'blob', // important
            headers: {
                //`
                /* "content-type":"application/octet-stream" */
            },
            data: {
                fechaEntrega,
                orden,
                vendedor,
                fecha,
                ordenpedido,
                personacargo,
                producto,
                modelo: model || 'NO DEFINIDO',
                medida: medida || 'NO DEFINIDO',
                color: color || 'NO DEFINIDO',
                posicion,
                observacion,
                items: payload.items
                // items2
            }
        })
        //console.log('OUTPUT : ', response.data)
        const name = `ORDEN EQUIPAMIENTO N ${orden}`
        await downloadFile(response.data, name, 'docx')
    },
    async reportTransport({ rootState, rootGetters, dispatch }, myListPedidos) {
        console.log('reportTransport', myListPedidos)

        // items.push({
        //     ...x,

        // })
        let items = []
        for (const x of myListPedidos) {
            const detalleUbigeo = x.ubigeo
                ? await dispatch('reparto/detailsUbigeo', x.ubigeo, {
                      root: true
                  })
                : null
            const provincia = detalleUbigeo ? detalleUbigeo[0].provincia : null
            const departamento = detalleUbigeo ? detalleUbigeo[0].departamento : null
            const distrito = detalleUbigeo ? detalleUbigeo[0].distrito : null
            items.push({
                fecha: x.check_in,
                idTicket: x.id,
                distrito: `${distrito} , ${provincia} , ${departamento}`,
                description: x.productos,

                fullName: x.atributos.fullNameReceptor,
                phoneNumber: x.celularContacto,
                locations: x.direccion,
                guide: x.numeroGuiaRemision,
                flete: x.montoDelivery,
                timeToDelivery: ''
            })
        }
        const response = await request({
            url: `/ms/carbone/v1.0/report/16`,
            method: 'POST',
            responseType: 'blob', // important
            headers: {
                //`
                /* "content-type":"application/octet-stream" */
            },
            data: items
        })
        //console.log('OUTPUT : ', response.data)
        const name = `RUTA_TRANSPORTE_${myListPedidos[0].fecha}_${Date.now()}`
        await downloadFile(response.data, name, 'xlsx')
    },
    async reportGastosMesSedeNew({ rootState, rootGetters, dispatch }, payload) {
        try {
            //console.log('payload ', payload)

            const sede = getValue(payload.idSede, rootGetters['sedes/_sedes'], 'id', 'detail')
            const totalGastos = await callAsync(`/api-loopback/tm_compras/getGastosByMes/${payload.idSede}/${payload.fecha}`, 'GET')
            let items = []
            totalGastos.map((y) => {
                const itemsCompras = JSON.parse(y.itemGastos)

                if (itemsCompras.length > 0) {
                    const detalle = itemsCompras[0].description || ''
                    //console.log('detalle ', detalle)
                    const body = new Gastos({
                        ...y,
                        tipoDocumento: getValue(y.idTipoDocumento, rootState.utilities.tipoDocumento, 'id', 'text'),
                        sede: getValue(y.idSede, rootGetters['sedes/_sedes'], 'id', 'detail'),
                        moneda: getValue(y.idTipoMoneda, rootState.utilities.tipoMoneda, 'id', 'text'),
                        importeTotalSoles: y.idTipoMoneda == 2 ? y.importe * y.tipoCambio : y.importe,
                        detalle,
                        categoria: getValue(y.id_tipo_gasto, rootState.utilities.listaCategoria, 'id_tipo_gasto', 'tipo_gasto'),
                        tipoComprobante: getValue(y.idTipoComprobante, rootState.utilities.tipoComprobante, 'id', 'descripcion'),
                        tipoPago: getValue(y.tipoPago, rootState.utilities.tipoGastos, 'id', 'text')
                    })

                    items.push(body)
                }
            })
            const output = sortItems(items, 'fecha', -1, 2)
            //console.log('output', output)

            const response = await request({
                url: `/ms/carbone/v1.0/report/4`,
                method: 'POST',
                responseType: 'blob', // important
                headers: {
                    //
                    /* "content-type":"application/octet-stream" */
                },
                data: output
            })
            //console.log('OUTPUT : ', response.data)
            const name = `REPORTE_GASTOS_MES_${sede}`
            await downloadFile(response.data, name, 'xlsx')
        } catch (error) {
            //console.log('error ', error)
        }
    },
    async reporteOrdenPedido({ rootState, rootGetters, dispatch }, data) {
        try {
            console.log('reporteOrdenPedido ', data)
            const bussines = rootState.bussines.bussines

            let departamento = { name: null }
            let provincia = { name: null }
            let distrito = { name: null }
            //const nombreColaborador = (data.idColaborador = 74)

            try {
                if (data.ubigeo) {
                    const isDepartamento = data.ubigeo.slice(0, 2)
                    const isProvincia = data.ubigeo.slice(0, 4)
                    const isDistrito = data.ubigeo
                    departamento = rootState.sedes.departamentos.find((x) => x.id === isDepartamento)
                    await dispatch('sedes/getDistrito', isProvincia, { root: true })
                    await dispatch('sedes/getProvincia', isDepartamento, { root: true })
                    provincia = rootState.sedes.provincias.find((x) => x.id === isProvincia)
                    distrito = rootState.sedes.distritos.find((x) => x.id === isDistrito)
                }
            } catch (error) {
                //console.log('err -ubigeo ', error)
            }
            let subTotal = 0
            let descTotal = 0
            let igvTotal = 0
            let total = 0
            let productos = []
            try {
                if (typeof data.productos == 'string' && data.productos) {
                    //console.log('here -1')
                    productos = JSON.parse(data.productos)
                    productos = typeof productos == 'string' ? JSON.parse(productos) : productos
                    //console.log('here --2')
                } else {
                    //console.log('here -3')
                    productos = data.productos
                    //console.log('here -4')
                }
            } catch (error) {
                //console.log('here -5')
                productos = []
            }
            //console.log('productos ', productos)
            //console.log('typeof data.productos ', typeof productos)

            productos.map((x) => {
                total += Number(x.subTotal)
                descTotal += Number(x.descuentos)
            })
            subTotal = total / 1.18
            igvTotal = total - subTotal

            const importes = {
                sT: formatNumber(subTotal, data.idTipoMoneda),
                dT: formatNumber(descTotal, data.idTipoMoneda),
                iT: formatNumber(igvTotal, data.idTipoMoneda),
                tL: formatNumber(total, data.idTipoMoneda)
            }
            let itemsProductos = []

            productos.map((y) =>
                itemsProductos.push({
                    dS: y.descuentos,
                    dC: y.description,
                    iC: y.internal_id,
                    mC: y.marca,
                    uN: Number(y.unidades),
                    pR: Number(y.price).toFixed(2),
                    sT: Number(y.subTotal).toFixed(2)
                })
            )
            console.log('body__')
            console.log('data********', { ...data })
            const body = new Orden({
                ...data,
                importes,
                bussines,
                itemsProductos,
                nombreContacto: data.atributos.fullNameReceptor || null,
                tipoDocumento: getValue(data.idTipoDocumento, rootState.utilities.tipoDocumento, 'id', 'text'),
                sede: getValue(data.idSede, rootGetters['sedes/_sedes'], 'id', 'detail'),
                moneda: getValue(data.idTipoMoneda, rootState.utilities.tipoMoneda, 'id', 'text'),
                importeTotalSoles: data.idTipoMoneda == 2 ? data.importe * data.tipoCambio : data.importe,
                tipoComprobante: getValue(data.idTipoComprobante, rootState.utilities.tipoComprobante, 'id', 'descripcion'),
                bancoDestino: getValue(data.idBancoDestino, rootState.utilities.tipoBancoDestino, 'id', 'text'),
                monedaCliente: getValue(data.idTipoMonedaRecibido, rootState.utilities.tipoMoneda, 'id', 'text'),
                destino: getValue(data.destino, rootState.utilities.tipoDestino, 'id', 'text'),
                formaEntrega: getValue(data.formaEntrega, rootState.utilities.tipoFormaEntrega, 'id', 'text'),
                //agenciaEnvio: getValue(data.agenciaEnvio, rootState.utilities.tipoAgenciaEnvio, 'id', 'text'),
                tipoEntrega: getValue(data.tipoEntrega, rootState.utilities.tipoSelectEntrega, 'id', 'text'),
                formaPago: getValue(data.formaPago, rootState.utilities.tipoBancoDestino, 'id', 'text'),
                departamento: departamento.name,
                provincia: provincia.name,
                distrito: distrito.name,
                documentoContacto: data.atributosdocumentoReceptor || null
            })
            console.log(body)
            const response = await request({
                url: `/ms/carbone/v1.0/report/5`,
                method: 'POST',
                responseType: 'blob', // important
                headers: {
                    //
                    /* "content-type":"application/octet-stream" */
                },
                data: body
            })
            //console.log('OUTPUT : ', response)
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            const timestamp = Date.now()
            const name = `ORDEN_PEDIDO_${data.id}_Fech_${data.check_in}_${data.full_name}_${timestamp}`
            link.setAttribute('download', `${name}.docx`)
            document.body.appendChild(link)
            link.click()
            return url
        } catch (error) {
            console.log('error ', error)
        }
    },
    async reporteDatosEnvio({ rootState, rootGetters, dispatch }, data) {
        try {
            //console.log('reporteDatosEnvio', data)
            console.log('data.atributos.bultos?data.atributos.bultos:1', data.atributos.bultos ? data.atributos.bultos : 1)
            console.log('atributos.bultos', data.atributos.bultos)
            const numberBultos = data.atributos.bultos ? data.atributos.bultos : 1
            console.log('numberBultos', numberBultos)
            const bulto = []
            for (let index = 1; index <= numberBultos; index++) {
                bulto.push({ number: index })
            }
            const formaEntrega = getValue(data.formaEntrega, rootState.utilities.tipoFormaEntrega, 'id', 'text')
            const bussines = rootState.bussines.bussines
            const tipoDoc = parseInt(data.tipoDoc)
            const body = {
                ...data,
                bulto,
                bultoTotal: numberBultos,
                producto: data.atributos.nombreProducto,
                numeroDocumento: data.atributos.documentoReceptor,
                full_name: data.atributos.fullNameReceptor,
                telefono: data.celularContacto,
                direccion: data.direccion,
                idRe: data.numeroGuiaRemision,
                formaEntrega: formaEntrega
            }
            console.log('body', body)
            console.log('tipoDoc', tipoDoc)
            const response = await request({
                url: `/ms/carbone/v1.0/report/${tipoDoc}`,
                method: 'POST',
                responseType: 'blob', // important
                headers: {
                    //
                    /* "content-type":"application/octet-stream" */
                },
                data: body
            })
            const name = `DATOS_ENVIO_${data.id}_${data.full_name}`
            //console.log('OUTPUT : ', response.data)
            await downloadFile(response.data, name, 'pdf', true)
        } catch (error) {
            //console.log('error ', error)
        }
    },
    async reporteTipoVentaV2({ rootState, rootGetters, dispatch }, payload) {
        try {
            console.log('payload_reporteTipoVentaV2', payload)
            const { sede, idSede, colaborador, idColaborador, filterVentas, filterCotizaciones, fecha } = payload
            const findSede = sede.find((x) => x.id == idSede)
            const { tipoBancoDestino, paymentMethod } = rootState.utilities
            const findColaborador = colaborador.find((x) => x.id == idColaborador)
            const filterTypeBank = tipoBancoDestino.filter((k) => k.idBussines == findColaborador.idBussines)
            console.log('filterTypeBank', filterTypeBank)
            let montoSoles = 0
            let montoDolares = 0
            let soles = {
                interbank: 0,
                bcp: 0,
                scotiabank: 0,
                bbva: 0,
                tarjeta: 0,
                efectivo: 0
            }
            let dolares = {
                bcp: 0,
                dolares: 0,
                interbank: 0,
                efectivo: 0
            }
            const productos = []
            let saldoVenta = 0
            let formaPago = '-'
            console.log('findColaborador', findColaborador)
            for (const x of filterVentas) {
                console.log('x', x)

                const { data } = await axios({
                    url: `/api-loopback/tm_pagos?filter={ "where": { "and": [{ "idVenta": ${x.id}},{"status":1}]}}`,
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                })
                data.map((y) => {
                    console.log('y', y)
                    formaPago = paymentMethod.find((k) => k.id == y.idTipoPago).text
                    const findBank = filterTypeBank.find((k) => k.id == y.idBancoDestinoExternal)
                    console.log('findBank', findBank)
                    saldoVenta += y.importe
                    if (y.idTipoMoneda == 1) {
                        montoSoles += y.importe
                    } else {
                        montoDolares += y.importe
                    }
                    switch (findBank.id) {
                        case 1:
                        case 7:
                        case 9:
                        case 11:
                        case 15:
                        case 17:
                            soles.bcp += y.importe
                            break
                        case 18:
                        case 19:
                            soles.efectivo += y.importe
                            break
                        case 2:
                        case 16:
                            dolares.bcp += y.importe
                            break
                        case 3:
                            soles.interbank += y.importe
                            break
                        case 4:
                            dolares.interbank += y.importe
                            break
                        case 5:
                            soles.scotiabank += y.importe
                            break
                        default:
                            if (y.idTipoMoneda == 1) {
                                if (y.idTipoPago == 2) {
                                    soles.tarjeta += y.importe
                                } else {
                                    soles.efectivo += y.importe
                                }
                            } else {
                                dolares.efectivo += y.importe
                            }
                            break
                    }
                })
                let text = ''

                x.privados &&
                    x.privados.map((y) => {
                        console.log('privados', y)
                        text = text.concat(' ', `${y.description && y.description.slice(0, 10)}`)
                    })
                x.productos.map((y) => {
                    console.log('y_productos', y)
                    text = text.concat(' ', `${y.description && y.description.slice(0, 10)}-${y.unidades}`)
                })
                productos.push({
                    formaPago,
                    ticket: x.id,
                    detalle: text,
                    importe: saldoVenta,
                    saldo: x.importeTotal - saldoVenta,
                    total: x.importeTotal
                })
                saldoVenta = 0
            }
            soles.ingreso = montoSoles
            const body = {
                sede: findSede?.detail,
                fecha,
                vendedora: findColaborador?.name,
                numCotizacion: filterCotizaciones.length,
                numVenta: filterVentas.length,
                montoSoles,
                montoDolares,
                soles,
                dolares,
                productos
            }
            const response = await request({
                url: `/ms/carbone/v1.0/report/18`,
                method: 'POST',
                responseType: 'blob', // important
                headers: {
                    //
                    /* "content-type":"application/octet-stream" */
                },
                data: body
            })
            const name = `DATOS_ENVIO_${fecha}_${findColaborador?.name}`
            console.log('OUTPUT : ', response.data)
            await downloadFile(response.data, name, 'xlsx')
        } catch (error) {
            console.log('error', error)
        }
    },
    async reporteVentasGeneradas({ commit, rootState }, payload) {
        try {
            //console.log('payload----', payload)
            const { idSede, sede, colaborador, idColaborador, fecha, idBussines } = payload
            const cotizacion = await request({
                url: `ms/migraciones/v1.0/colaborador/cotizacion`,
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                data: {
                    idColab: idColaborador,
                    idBussines,
                    fecha
                }
            })
            const filterCotizaciones = cotizacion.data.length
            console.log('filterCotizaciones---', filterCotizaciones)
            const { data } = await request({
                url: `/ms/migraciones/v1.0/ventas/realizadas`,
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                data: {
                    idColaborador,
                    fecha
                }
            })

            let montoSoles = 0
            let montoDolares = 0
            let bankSoles = []
            let bankDolares = []

            const productos = []
            let saldoVenta = 0
            const numVenta = data.length
            console.log('data-----', data)
            for (const { importeRecibido, moneda, idBancoDestinoExternal, formaPago, detalle, saldoPendiente, valorTotal, ticket } of data) {
                const bancoVenta = await request({
                    url: `/api-orquestador/ventas/v2.0/banco`,
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: {
                        idSede,
                        idBancoDestinoExternal,
                        importeRecibido,
                        moneda
                    }
                })
                saldoVenta += importeRecibido
                if (moneda == 'S/.') {
                    montoSoles += importeRecibido
                } else {
                    montoDolares += importeRecibido
                }
                bancoVenta.data.tipoMoneda == 'S/.' ? bankSoles.push(bancoVenta.data) : bankDolares.push(bancoVenta.data)
                productos.push({
                    formaPago,
                    ticket,
                    moneda,
                    detalle,
                    importe: saldoVenta,
                    saldo: saldoPendiente,
                    total: valorTotal
                })
                saldoVenta = 0
            }
            let soles = []
            let dolares = []
            const contador = {}
            if (bankSoles.length > 0) {
                const priceProd = bankSoles.reduce((total, elemento) => total + elemento.montoPagado, 0)
                bankSoles.forEach((x) => {
                    contador[x.idBank] = (contador[x.idBank] || 0) + 1
                    if (contador[x.idBank] === 1) {
                        soles.push({ description: x.description, montoPagado: priceProd })
                    }
                })
            }
            if (bankDolares.length > 0) {
                const priceProd = bankDolares.reduce((total, elemento) => total + elemento.montoPagado, 0)
                bankDolares.forEach((x) => {
                    contador[x.idBank] = (contador[x.idBank] || 0) + 1
                    if (contador[x.idBank] === 1) {
                        dolares.push({ description: x.description, montoPagado: priceProd })
                    }
                })
            }
            const body = {
                sede,
                fecha,
                vendedora: colaborador,
                numCotizacion: filterCotizaciones,
                numVenta,
                montoSoles,
                montoDolares,
                soles,
                dolares,
                productos
            }
            const response = await request({
                url: `/ms/carbone/v1.0/report/19`,
                method: 'POST',
                responseType: 'blob', // important
                headers: {
                    //
                    /* "content-type":"application/octet-stream" */
                },
                data: body
            })
            const name = `REPORTE_VENTA_${fecha}_${colaborador}`
            console.log('OUTPUT : ', response.data)
            await downloadFile(response.data, name, 'pdf')
        } catch (error) {
            console.log(error)
        }
    },
    async reportComprasV2MesSedeNew({ rootState, rootGetters, dispatch }, payload) {
        try {
            console.log('reportComprasV2MesSedeNew', payload)
            const { data } = await request({
                url: `/ms/migraciones/v2.0/comprasGastos?idSede=${payload.idSede}&date=${payload.fecha}`,
                method: 'GET'
            })
            const itemsArray = []
            console.log('data', data)
            data.gastos.map((x) =>
                itemsArray.push({
                    fecha: x.fechaEmision.split('T')[0],
                    tipoGasto: x.series ? 'Compra' : 'Gasto',
                    detalle: x.description,
                    tipoCambio: x.tipoCambio,
                    precio: x.importe,
                    numeroDocumento: x.perNumeroDocumento || null,
                    nombre: x.perNombresCompleto || null,
                    tipoComprobante: x.tipoComprobante || null,
                    series: x.series,
                    numeroComprobante: x.ordenCompra,
                    colaborador: x.nombreColaborador
                })
            )
            console.log('itemsArray', itemsArray)
            const headers = [
                'fecha',
                'tipoGasto',
                'detalle',
                'tipoCambio',
                'precio',
                'numeroDocumento',
                'nombre',
                'tipoComprobante',
                'series',
                'numeroComprobante',
                'colaborador'
            ]
            const filename = `Reporte ${payload.fecha}`
            exportXLSX({ headers, campos: headers, arrayData: itemsArray }, { filename })
        } catch (error) {
            //console.log('error ', error)
        }
    },
    async reporteTipoVenta({ commit, rootState, rootGetters, dispatch }, payload) {
        try {
            console.log('payload', payload)
            const { data } = await request({
                url: `/ms/migraciones/v1.0/reportes/mensual/tipo/venta?idSede=${payload.idSede}&date=${payload.fecha}`,
                method: 'GET'
            })
            console.log('(data(aqui----))', data)

            const headers = ['fecha', 'check_in', 'duration', 'ticket', 'tipoVenta', 'operario', 'room', 'detalle', 'monto']
            const filename = `Reporte_${payload.fecha}_${payload.idSede}_${Date.now()}`
            exportXLSX({ headers, campos: headers, arrayData: data }, { filename })
        } catch (error) {
            console.log(error)
        }
    },
    async reporteBanco({ commit, rootState, rootGetters, dispatch }, payload) {
        try {
            console.log('payload---', payload)
            const { data } = await request({
                url: `/ms/migraciones/v1.0/banco/registro`,
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                data: payload
            })
            console.log('response---', data)
            if (!data) return
            const headers = [
                'id',
                'Fecha Emisión',
                'RUC. N°',
                'Razon Social o Apellidos y Nombres',
                'Concepto',
                'Tipo de comprobante',
                'Serie-N° Comprob.',
                'Ingresos',
                'Egresos',
                'SALDO'
            ]
            const campos = ['id', 'fechaEmision', 'numeroDocumento', 'razonSocial', 'producto', 'comprobante', 'serie', 'ingreso', 'egreso', 'saldo']

            const filename = `Reporte ${payload.fechaInicio} - ${payload.fechaFin}`
            exportXLSX({ headers, campos, arrayData: data }, { filename })
        } catch (error) {
            console.log(error)
        }
    },
    async reportAsistenciaMesSedeNew({ rootState, rootGetters, dispatch }, payload) {
        try {
            const sede = getValue(payload.idSede, rootGetters['sedes/_sedes'], 'id', 'detail')
            //console.log('sede ', sede)
            let output = []
            await dispatch(
                'asistencia/getLista',
                {
                    idSede: payload.idSede,
                    tipo: 2,
                    fecha: payload.fecha
                },
                {
                    root: true
                }
            )
            rootState.asistencia.listaAsistencia.map((x) => {
                output.push({
                    ...x,
                    horaIngreso: x.ingreso,
                    horaSalida: x.salida,
                    fecha: x.fechaCreacion,
                    sede: getValue(x.idSede, rootGetters['sedes/_sedes'], 'id', 'detail'),

                    nombreColaborador: x.full_name
                })
            })
            const response = await request({
                url: `/ms/carbone/v1.0/report/9`,
                method: 'POST',
                responseType: 'blob', // important
                headers: {
                    //
                    /* "content-type":"application/octet-stream" */
                },
                data: output
            })
            //console.log('OUTPUT : ', response.data)
            const name = `REPORTE_ASISTENCIA_MES_${sede}`
            await downloadFile(response.data, name, 'xlsx')
        } catch (error) {
            //console.log('error ', error)
        }
    },
    async sendEmailProductoPreparando({ rootState, rootGetters, dispatch }, payload) {
        //console.log('generando cotizacion y enviar email', rootState.users.user)
        console.log('payload----', payload)
        const user = rootState.users.user
        console.log('user---', user)
        const body = {
            idCorreoCoorporativo: 2,
            idVenta: payload.id,
            isEmailPreparando: true,
            correoDestino: payload.emailClient,
            usuario: {
                correo: user.correo,
                telefono: user.telefono,
                nombres: user.nombres + ' ' + user.apellidos
            }
        }
        console.log('body---', body)
        return await request({
            //url: `http://localhost:3000/orq/ventas/v2.0/mail/preparando`,
            url: `/api-orquestador/ventas/v2.0/mail/preparando`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: body
        })
    },
    async sendOrdenRecojo({ rootState, rootGetters, dispatch }, payload) {
        //console.log('generando cotizacion y enviar email', rootState.users.user)
        const { data } = await request({
            //url: `http://localhost:3000/orq/ventas/v2.0/mail/preparando`,
            url: `/api-orquestador/v2.0/email/orden/recojo`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            }
        })
        return data
    },
    async sendEmailProductoTerminado({ rootState, rootGetters, dispatch }, payload) {
        console.log('payload----', payload)
        //console.log('generando cotizacion y enviar email', rootState.users.user)
        const body = {
            idCorreoCoorporativo: payload.idCorreoCoorporativo,
            idVenta: payload.idVenta,
            isEmailTerminado: true,
            correoDestino: payload.correoDestino,
            saldoPendiente: payload.saldoPendiente
        }
        console.log('body---', body)
        return await request({
            url: `/api-orquestador/ventas/v2.0/mail/terminado`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: body
        })
    },
    async sendEmailProductoEnCamino({ rootState, rootGetters, dispatch }, payload) {
        //console.log('generando cotizacion y enviar email', rootState.users.user)
        console.log('payload----', payload)
        const body = {
            idCorreoCoorporativo: payload.idCorreoCoorporativo,
            idVenta: payload.idVenta,
            isEmailEnCamino: true,
            full_name: payload.full_name,
            correoDestino: payload.correoDestino
        }
        console.log('body---', body)
        return await request({
            url: `/api-orquestador/ventas/v2.0/mail/enCamino`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: body
        })
    },
    async sendEmailProductoEntregado({ rootState, rootGetters, dispatch }, payload) {
        console.log('payload----', payload)
        // //console.log('check_in', payload.check_in)
        // const myTimezone = formatInTimeZone(new Date(), 'America/Lima', 'yyyy-MM-dd')
        // //console.log('myTimezone', myTimezone)
        //console.log('sendEmailProductoEntregado', rootState.users.user)
        //console.log('payload', payload)
        let tipoEnvio

        switch (payload.formaEntrega) {
            case 1:
                tipoEnvio = 'DOMICILIO'
                break
            case 2:
                tipoEnvio = 'AGENCIA'
                break

            default:
                tipoEnvio = 'RECOJO EN TIENDA'
                break
        }

        const body = {
            tipoEnvio,
            fecha: payload.check_in,
            direccion: payload.direccion ? payload.direccion : 'Tienda',
            ticket: payload.idVenta,
            product: payload.nameProduct,
            urlProduct: payload.link,
            idVenta: payload.idVenta,
            correoDestino: payload.correoDestino,
            idCorreoCoorporativo: payload.idCorreoCoorporativo,
            isEmailEntregado: true,
            fullname: payload.full_name,
            customData: payload.atributos
        }
        console.log('body', body)
        return await request({
            url: `/api-orquestador/ventas/v2.0/mail/entregado`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: body
        })
    },
    async pathRoute({ rootState, rootGetters, dispatch, commit }, type) {
        commit('UPDATE_WHACHTREPORT', type)
        //console.log("routePath-.-.-",type)
    },
    async sendGuiaRemision({ rootState, rootGetters, dispatch }, payload) {
        console.log('payload---', payload)
        const prods = []
        payload.productos.forEach((x) => {
            prods.push({
                attributes: x.attributes,
                description: x.description,
                internal_id: x.internal_id,
                quantity: JSON.parse(x.unidades),
                item_id: x.id,
                unit_type_id: x.unit_type_id,
                id: x.id,
                IdLoteSelected: '',
                lot_group: null
            })
        })
        //console.log("prods---",prods)
        const body = {
            order_form_external: payload.id + ' | ' + payload.numeroComprobante,
            document_type_id: '09',
            date_of_shipping: payload.atributos.fechaEnvio,
            customer_id: payload.idCliente,
            transfer_reason_description: payload.referencia,
            transport_mode_type_id: payload.atributos.idModotransporte.toString().padStart(2, '0'),
            transfer_reason_type_id: '01',
            total_weight: JSON.parse(payload.atributos.pesoProducto),
            packages_number: JSON.parse(payload.atributos.bultos),
            observations: payload.receptor,
            items: prods,
            delivery: {
                id: 1,
                location_id: payload.idLocation,
                address: payload.direccion
            }
        }
        console.log('body----', body)
        const { data } = await request({
            url: `/ms/migraciones/v1.0/guiaRemision`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                dataGuia: body,
                idSede: rootState.users.user.idSede,
                idChofer: payload.atributos.idModotransporte == 2 ? payload.atributos?.idChofer : null,
                userDocumento: rootState.users.user.documento,
                idTranporte: payload.atributos.idModotransporte == 2 ? payload.atributos?.placaVehiculo : null,
                distruibuidoraEnvio: payload.agenciaEnvio
            }
        })
        window.open(data.url, '_blank')
        return { numeroGuiaRemision: data.idGuia, urlNumeroGuiaRemision: data.url }
    },
    async sendEmailCotizacion({ rootState, rootGetters, dispatch }, payload) {
        //console.log('generando cotizacion y enviar email')
        const pdfFile = await dispatch('reporte/reporteCotizacion', payload, {
            root: true
        })
        //console.log('pdf ', pdfFile)
        const body = {
            payload: {
                destinatarios: payload.correo,
                idTemplate: 1,
                asunto: `Bienvenido a Cuba&Spa Soluciones | Has recibido una Cotización ${payload.id}            `,
                full_name: payload.full_name,
                attachments: [pdfFile]
            },
            setup: {
                serviceId: 4
            }
        }
        const response = await request({
            url: `/api-orquestador/email/v2.0/send`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
                //
            },
            data: body
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
