import { sortItems } from '@/utils/genericUtils'
import request from '@/utils/request'
import axios from 'axios'

const state = {
    listaCategoria: [
        {
            id_tipo_gasto: 1,
            tipo_gasto: 'COSTOS DE VENTA'
        },
        {
            id_tipo_gasto: 2,
            tipo_gasto: 'ALQUILER'
        },

        {
            id_tipo_gasto: 3,
            tipo_gasto: 'LUZ'
        },
        {
            id_tipo_gasto: 4,
            tipo_gasto: 'AGUA'
        },
        {
            id_tipo_gasto: 5,
            tipo_gasto: 'TELEFONO'
        },
        {
            id_tipo_gasto: 6,
            tipo_gasto: 'GAS'
        },

        {
            id_tipo_gasto: 7,
            tipo_gasto: 'HIERBAS'
        },

        {
            id_tipo_gasto: 8,
            tipo_gasto: 'SUELDOS Y SALARIOS'
        },
        {
            id_tipo_gasto: 9,
            tipo_gasto: 'ESSALUD'
        },
        {
            id_tipo_gasto: 10,
            tipo_gasto: 'COMISIONES DE VISA'
        },
        {
            id_tipo_gasto: 11,
            tipo_gasto: 'OTROS GASTOS'
        },
        {
            id_tipo_gasto: 12,
            tipo_gasto: 'IMPUESTO A LA RENTA'
        }
    ],
    tipoGastos: [
        { id: 1, text: 'EFEC.' },
        { id: 2, text: 'TRANSF.' },
        { id: 3, text: 'OTROS' },
        { id: 4, text: 'PEND. PAGO' }
    ],
    tipoDestino: [
        { id: 1, text: 'LIMA' },
        { id: 2, text: 'PROVINCIA' }
    ],
    tipoFormaEntrega: [
        { id: 1, text: 'DOMICILIO' },
        { id: 2, text: 'AGENCIA' },
        { id: 4, text: 'RECOJO SODIMAC' },
        { id: 5, text: 'RECOJO RIPLEY' },
        { id: 6, text: 'RECOJO FALABELLA' },
        { id: 7, text: 'AGENCIA CON DELIVERY' },
        { id: 8, text: 'RECOJO TIENDA VILLA MARIA' },
        { id: 9, text: 'RECOJO TIENDA SMP' },
        { id: 10, text: 'RECOJO TIENDA CHICLAYO' },
        { id: 11, text: 'RECOJO ALMACEN' }
    ],
    tipoAgenciaEnvio: [
        { id: 1, text: 'SHALOM' },
        { id: 2, text: 'MARVISUR' },
        { id: 3, text: 'HERMANOS MARIN' },
        { id: 4, text: 'CARRAZOS' },
        { id: 5, text: 'VELOZ' },
        { id: 6, text: 'MOVIL TOURS' },
        { id: 7, text: 'CIVA' },
        { id: 8, text: 'TEPSA' },
        { id: 9, text: 'CRUZ DE SUR' },
        { id: 10, text: 'ITZZA' },
        { id: 11, text: 'FLORES HERMANOS' },
        { id: 12, text: 'TRANSMOTAR' },
        { id: 13, text: 'OTRA' }
    ],
    tipoSelectEntrega: [
        { id: 1, text: 'CLIENTE RECOGE EN OFICINA' },
        { id: 2, text: 'DELIVERY A DOMICILIO LIMA' },
        { id: 3, text: 'ENVIO POR AGENCIA CERCANA' },
        { id: 4, text: 'ENVIO POR OTRA AGENCIA' },
        { id: 5, text: 'RECOJO POR APLICATIVO' },
        { id: 6, text: 'ENVIO POR MOVILIDAD EXTERNA' }
    ],
    tipoEstadoDeuda: [
        { text: 'PAGADO', value: 2 },
        { text: 'PENDIENTE', value: 1 }
    ],
    tipoBancoDestino: [],
    allBank: [
        { id: 1, description: 'BANCO SCOTIABANK' },
        { id: 2, description: 'BANCO DE CREDITO DEL PERU' },
        { id: 5, description: 'BBVA CONTINENTAL' },
        { id: 6, description: 'INTERBANK' },
        { id: 7, description: 'BANCO DE LA NACION' },
        { id: 8, description: 'BCP PERSONAL LIMA' },
        { id: 9, description: 'YAPE LIMA' },
        { id: 10, description: 'YAPE PISCO' },
        { id: 11, description: 'YAPE CHICLAYO' },
        { id: 12, description: 'CAJA GENERAL' }
    ],
    empresa: [
        { id: 1, idSede: 2, description: 'KUNTE SPA' },
        { id: 2, idSede: 5, description: 'CUBA SPA' },
        { id: 4, idSede: 11, description: 'KUNTE SPA SOLUCIONES' }
    ],
    tipoDocumento: [
        { text: 'Doc.trib.no.dom.sin.ruc', id: '0' },
        { text: 'DNI', id: '1' },
        { text: 'CE', id: '4' },
        { text: 'RUC', id: '6' },
        { text: 'Pasaporte', id: '7' },
        { text: 'Ced. Diplomática de identidad', id: 'A' },
        { text: 'Documento identidad país residencia-no.d', id: 'B' },
        { text: 'Tax Identification Number - TIN – Doc Trib PP.NN', id: 'C' },
        { text: 'Identification Number - IN – Doc Trib PP. JJ', id: 'D' },
        { text: 'TAM- Tarjeta Andina de Migración', id: 'E' }
    ],
    tipoComprobante: [
        { descripcion: 'Factura', id: 1 },
        { descripcion: 'Boleta', id: 2 },
        { descripcion: 'Recibo por Honorario', id: 3 },
        { descripcion: 'Recibo de egreso', id: 4 },
        { descripcion: 'Recibo de Ingreso', id: 5 }
    ],
    tipoRegistroDocumento: [
        { descripcion: 'Nota de Crédito', id: 1 },
        { descripcion: 'Nota de Débito', id: 2 },
        { descripcion: 'Constancia Detracción', id: 3 },
        { descripcion: 'Constancia Retención', id: 4 }
    ],
    tipoComprobanteFact: [
        { descripcion: 'Factura', id: 1 },
        { descripcion: 'Boleta', id: 2 },
        { descripcion: 'Nota de Venta', id: 3 }
    ],
    listOrigen: ['WEB', 'WHATSAPP', 'FACEBOOK', 'INSTAGRAM', 'YOUTUBE', 'GOOGLE BUSINES', 'TELEFONO', 'LLAMADA', 'OTROS'],
    tipoMoneda: [
        { id: 1, text: 'S/.', value: 'Soles', country: 'es-PE', currency: 'PEN' },
        { id: 2, text: '$', value: 'Dolares', country: 'en-US', currency: 'USD' }
    ],
    condicionPago: [
        { id: 1, value: 'Contado' },
        { id: 2, value: 'Crédito' }
    ],
    enviarCorreo: [
        { id: 1, value: 'En Formato A4' },
        { id: 2, value: 'En Formato Ticket' },
        { id: 3, value: 'No enviar a correo' }
    ],

    tipoPago: [
        { id: 1, descripcion: 'EFECTIVO' },
        { id: 2, descripcion: 'TRANSFERENCIA' }
    ],
    selectCargo: [
        { id: 1, text: 'sin permisos' },
        { id: 2, text: 'Administrador' },
        { id: 3, text: 'Super User' },
        { id: 4, text: 'Logistica' },
        { id: 5, text: 'Reparto' },
        { id: 6, text: 'Vendedor' }
    ],
    selectEstado: [
        { id: 0, text: 'PENDIENTE' },
        { id: 1, text: 'FINALIZADO' }
    ],

    paymentMethod: [
        { id: 1, text: 'EFECTIVO' },
        { id: 2, text: 'TARJETA' },
        { id: 3, text: 'TRANSFERENCIA' },
        { id: 4, text: 'DEPOSITO' },
        { id: 5, text: 'PAGO LINK' },
        { id: 6, text: 'IZY QR' }
    ],
    itemsTypeReport: [
        { id: 3, text: 'VENTAS', nameDispatch: 'reporte/reporteTipoVenta' },
        { id: 4, text: 'GASTOS', nameDispatch: 'reporte/reportGastosMesSedeNew' },
        { id: 5, text: 'ASISTENCIAS', nameDispatch: 'reporte/reportAsistenciaMesSedeNew' },
        { id: 6, text: 'COMPRAS V2', nameDispatch: 'reporte/reportComprasV2MesSedeNew' },
        { id: 7, text: 'VENTAS V2', nameDispatch: 'reporte/reporteTipoVentaV2' }
    ],
    typeReport: [{ id: 3, text: 'VENTAS', nameDispatch: 'reporte/reporteTipoVenta' }],
    modoTransporte: [
        { id: 1, description: 'Transporte Publico' },
        { id: 2, description: 'Transporte Privado' }
    ]
}
const getters = {
    listTipoBancoDestino: (state, getters, rootState) => {
        const sede = rootState.sedes.sede
        let item = []
        state.tipoBancoDestino.map((x) => {
            /* state.tipoBancoDestino.findIndex((z) => (z.idSede = idSede)) */
            // const idOrder = x.idSede == sede.id ? 10 : 1
            // item.push({ ...x, idOrder, id: x.id.toString() })
            item.push({ ...x })
        })
        console.log('items_listTipoBancoDestino', sortItems(item, 'idOrder', 1))
        return sortItems(item, 'idOrder', 1)
    },
    listTipoBancoDestinoOwn: (state, getters, rootState) => {
        const idBussines = rootState.users.user.idBussines
        const items = state.tipoBancoDestino.filter((x) => x.idBussines == idBussines)
        return sortItems(items, 'idBank', 1)
    }
}
const mutations = {
    UPDATE_BANCOS_DESTINO(state, payload) {
        state.tipoBancoDestino = payload
    }
}
const actions = {
    async getBancos({ commit, state, rootState }) {
        console.log('getBancos ', state.tipoBancoDestino)
        const sede = rootState.sedes.sede

        console.log('getBancos ')
        const response = await request({
            url: `/ms/migraciones/v1.0/banks?idSede=${sede.id}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        console.log('response getBancos', response.data)
        commit('UPDATE_BANCOS_DESTINO', response.data)
    },
    async botWhatsappVerify({ commit, state, rootState }, requiredParams) {
        // Variable para verificar si todos los atributos están llenos
        let todosAtributosLlenos = true
        let empty = ''
        for (const key in requiredParams) {
            if (!requiredParams[key]) {
                todosAtributosLlenos = false
                empty = key
                break // Si al menos uno está vacío, no es necesario seguir verificando
            }
        }
        console.log('empty---', empty)
        if (todosAtributosLlenos) {
            // Todos los atributos del objeto no están vacíos
            return { status: true, empty }
        } else {
            return { status: false, empty }
        }
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
